import { AuthService } from './../../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  fromGroup: FormGroup;
  typeHoldPassword = 'password';
  typeNewPassword = 'password';
  typeConfirmPassword = 'password';
  eyeHoldPassword = 'far fa-eye';
  eyeNewPassword = 'far fa-eye';
  eyeConfirmPassword = 'far fa-eye';
  verif = false;

  constructor(
    private formBuilder: FormBuilder,
    private authSvc: AuthService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.fromGroup = this.formBuilder.group({
      password: [null, Validators.required],
      npassword: [null, Validators.required],
      cpassword: [null, Validators.required]
    }, {validators: this.verifPassword('npassword', 'cpassword')});
    this.verif = false;
  }

  verifPassword(npswd: string, cpswd: string) {
    return (group: FormGroup) => {
      const pswdInput = group.controls[npswd];
      const cpswdInput = group.controls[cpswd];
      if (pswdInput.value !== cpswdInput.value) {
        return cpswdInput.setErrors({notEquivalent: true});
      }
      return cpswdInput.setErrors(null);
    };
  }

  changeType(champ: string, event = null): void {
    if (event) {
      event.stopPropagation();
    }
    if (champ === 'password') {
      if (this.typeHoldPassword == 'password') {
        this.typeHoldPassword = 'text';
        this.eyeHoldPassword = 'far fa-eye-slash';
      } else {
        this.typeHoldPassword = 'password';
        this.eyeHoldPassword = 'far fa-eye';
      }
    } else if (champ === 'newPassword') {
      if (this.typeNewPassword == 'password') {
        this.typeNewPassword = 'text';
        this.eyeNewPassword = 'far fa-eye-slash';
      } else {
        this.typeNewPassword = 'password';
        this.eyeNewPassword = 'far fa-eye';
      }
    } else {
      if (this.typeConfirmPassword == 'password') {
        this.typeConfirmPassword = 'text';
        this.eyeConfirmPassword = 'far fa-eye-slash';
      } else {
        this.typeConfirmPassword = 'password';
        this.eyeConfirmPassword = 'far fa-eye';
      }
    }
  }

  annuler(event) {
    event.stopPropagation();
    this.typeHoldPassword = 'password';
    this.eyeHoldPassword = 'far fa-eye';
    this.typeNewPassword = 'password';
    this.eyeNewPassword = 'far fa-eye';
    this.typeConfirmPassword = 'password';
    this.eyeConfirmPassword = 'far fa-eye';
    this.initForm();
  }

  onSave(event) {
    event.stopPropagation();
    console.log(this.fromGroup.valid);
    console.log(this.fromGroup.value);
    if (this.fromGroup.valid) {
      this.verif = true;
      this.authSvc.updatePassword(this.fromGroup.value).subscribe(
        res => {
          if (res) {
            this.initForm();
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Le mot de passe est modifié',
              showConfirmButton: false,
              timer: 3000
            });
          } else {
            this.verif = false;
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Verifiez les infos saisies',
              showConfirmButton: false,
              timer: 3000
            });
          }
        }, err => {
          console.log(err);
          this.verif = false;
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Une erreur interne est servenue',
              showConfirmButton: false,
              timer: 3000
            });
        }
      );
    }
  }

}
