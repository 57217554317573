import { UserModel } from './../../../../models/user.model';
import { Role, RoleModel } from './../../../../models/role.model';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  user: UserModel;
  roles: RoleModel[] = [
    {
      url: 'home',
      values: [Role.ADMIN, Role.LOGICIEL, Role.LOGISTIQUE, Role.COMPTABILITE, Role.COMMERCIAL, Role.TECHNIQUE]
    },
    {
      url: 'produit',
      values: [Role.ADMIN, Role.LOGICIEL, Role.LOGISTIQUE]
    },
    {
      url: 'personnel',
      values: [Role.ADMIN, Role.LOGICIEL, Role.COMPTABILITE]
    },
    {
      url: 'vente',
      values: [Role.ADMIN, Role.LOGICIEL, Role.COMMERCIAL, Role.COMPTABILITE, Role.LOGISTIQUE]
    },
    {
      url: 'les-services',
      values: [Role.ADMIN, Role.LOGICIEL]
    },
    {
      url: 'categorie',
      values: [Role.ADMIN, Role.LOGICIEL, Role.COMPTABILITE, Role.LOGISTIQUE]
    },
    {
      url: 'stock',
      values: [Role.ADMIN, Role.LOGICIEL, Role.COMPTABILITE, Role.LOGISTIQUE]
    },
    {
      url: 'devise',
      values: [Role.ADMIN, Role.LOGICIEL, Role.COMPTABILITE]
    },
    {
      url: 'client',
      values: [Role.ADMIN, Role.LOGICIEL, Role.COMMERCIAL, Role.COMPTABILITE]
    },
    {
      url: 'mode-paiement',
      values: [Role.ADMIN, Role.LOGICIEL, Role.COMPTABILITE]
    },
    {
      url: 'caisse',
      values: [Role.ADMIN, Role.LOGICIEL, Role.COMPTABILITE]
    },
    {
      url: 'maintenance',
      values: [Role.ADMIN, Role.LOGICIEL, Role.TECHNIQUE]
    },
    {
      url: 'approvisionnement',
      values: [Role.ADMIN, Role.LOGICIEL]
    }
  ];

  constructor(
    private authSvc: AuthService,
    private router: Router
  ) {}


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(
        (resolve, reject) => {
          let _url: string = "";
              state.url.split("/").forEach(element => {
                  if(_url === "")
                      if(element !== "")
                          _url=element;
              });
              this.user = this.authSvc.userConnected.getValue();
              if (this.user.id && this.user.type !== 'client') {
                if (this.checkAuthorized(_url, this.user)) {
                  resolve(true);
                } else {
                  this.router.navigateByUrl('/home');
                }
              } else if (this.user.type == 'client') {
                reject(true);
              } else {
                const webToken = localStorage.getItem('web_token');
                if (webToken != null) {
                  const httpOptions = {
                      headers: new HttpHeaders({
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${webToken}`
                      })
                  };
                  this.authSvc.loadUser(httpOptions).subscribe(
                    res => {
                      this.authSvc.userConnected.next(res['data']);
                      if (this.checkAuthorized(_url, res['data'])) {
                        resolve(true);
                      } else {
                        this.router.navigateByUrl('/home');
                      }
                    }, err => {
                      console.log(err);
                      Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: err.error.message,
                        showConfirmButton: false,
                        timer: 2500
                      });
                      this.router.navigateByUrl('/auth/login', {replaceUrl: true});
                    }
                  );
                } else {
                  this.router.navigateByUrl('/auth/login', {replaceUrl: true});
                }
              }
        }
    );
  }

  checkAuthorized(url: string, user: UserModel): boolean {
    if (user && user.id) {
      if (user.type == Role.ADMIN) {
        return true;
      } else {
        const role: RoleModel = this.roles.find(r => r.url === url);
        if (user && user.id) {
          if (role.values.includes(user.employe.service.libelle)) {
            return true;
          }
        }
      }
    }
    return false;
  }
}
