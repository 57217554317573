export class Role {
  static ADMIN = 'admin';
  static PERSONNEL = 'personnel';
  static LOGICIEL = 'Service Logiciel';
  static COMMERCIAL = 'Service commercial';
  static TECHNIQUE = 'Service Technique';
  static COMPTABILITE = 'Service Comptabilité';
  static LOGISTIQUE = 'Service Logistique';
  static SECURITE = 'Service Securité';
}

export class RoleModel {
  constructor(
    public url: string,
    public values: string[]
  ) {}
}
