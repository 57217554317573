import { DefaultService } from './../services/default.service';
import { AuthService } from './../services/auth.service';
import { NgModule } from '@angular/core';



@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [
    AuthService,
    DefaultService
  ]
})
export class SharedModule { }
