<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a class="brand-link">
      <img src="../../../assets/logo.png" alt="Tinki Store Logo" class="brand-image img-circle elevation-3"
           style="opacity: .8">
      <span class="brand-text font-weight-light">{{ user?.employe?.service?.libelle ? user?.employe?.service?.libelle : 'Tinki Store'}}</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img [src]="user?.photo ? urlBase + user?.photo : '../../../assets/dist/img/avatar.png'" class="img-circle elevation-2" alt="User Image">
        </div>
        <div class="info">
          <a class="d-block">{{ user?.name }}</a>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-item">
            <a routerLink="/home" routerLinkActive="active" style="cursor: pointer;" class="nav-link">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>
                Dashboard
              </p>
            </a>
          </li>
          <li *ngIf="user?.employe?.service?.libelle != TECHNIQUE && user?.employe?.service?.libelle != LOGISTIQUE" class="nav-item">
            <a routerLink="/vente/pos" routerLinkActive="active" style="cursor: pointer;" class="nav-link">
              <i class="nav-icon fas fa-shopping-cart"></i>
              <p>
                Point de vente
              </p>
            </a>
          </li>
          <li class="nav-header">BOUTIQUE</li>
          <li *ngIf="user?.type == 'admin' || user?.employe?.service?.libelle == LOGICIEL || user?.employe?.service?.libelle == LOGISTIQUE" class="nav-item">
            <a routerLink="/produit" routerLinkActive="active" style="cursor: pointer;" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Produit
              </p>
            </a>
          </li>
          
          <li *ngIf="user?.employe?.service?.libelle != TECHNIQUE && user?.employe?.service?.libelle != COMMERCIAL" class="nav-item">
            <a routerLink="/stock" routerLinkActive="active" style="cursor: pointer;" class="nav-link">
              <i class="nav-icon fas fa-boxes"></i>
              <p>
                Stock
              </p>
            </a>
          </li>
          <li *ngIf="user?.type == 'admin' || user?.employe?.service?.libelle == LOGICIEL" class="nav-item">
            <a routerLink="/approvisionnement" routerLinkActive="active" style="cursor: pointer;" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Hist. approvisionnements
              </p>
            </a>
          </li>
          <li *ngIf="user?.employe?.service?.libelle != TECHNIQUE && user?.employe?.service?.libelle != COMMERCIAL" class="nav-item">
            <a routerLink="/categorie" routerLinkActive="active" style="cursor: pointer;" class="nav-link">
              <i class="nav-icon fas fa-table"></i>
              <p>
                Categorie
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a *ngIf="user?.employe?.service?.libelle != TECHNIQUE" routerLink="/vente/historique" routerLinkActive="active" class="nav-link">
              <i class="nav-icon ion ion-bag"></i>
              <p>
                Vente
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a *ngIf="user?.employe?.service?.libelle != TECHNIQUE" routerLink="/vente/commande" routerLinkActive="active" class="nav-link">
              <i class="nav-icon fa fa-weight"></i>
              <p>
                Commandes
              </p>
            </a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link">
              <i class="nav-icon fas fa-dolly-flatbed"></i>
              <p>
                Inventaire
              </p>
            </a>
          </li> -->
          <!-- <li class="nav-item">
            <a href="pages/gallery.html" class="nav-link">
              <i class="nav-icon fas fa-layer-group"></i>
              <p>
                Ajustement de stock
              </p>
            </a>
          </li> -->
          <li *ngIf="user?.type == 'admin' || user?.employe?.service?.libelle == LOGICIEL || user?.employe?.service?.libelle == COMPTABILITE" class="nav-item">
            <a routerLink="/caisse" routerLinkActive="active" style="cursor: pointer;" class="nav-link">
              <i class="nav-icon ion ion-pie-graph"></i>
              <p>
                Caisse
              </p>
            </a>
          </li>
          <ng-container *ngIf="user?.type == 'admin' || user?.employe?.service?.libelle == LOGICIEL || user?.employe?.service?.libelle == TECHNIQUE" >
            <li class="nav-header">Maintenance</li>
            <li class="nav-item">
              <a routerLink="/maintenance/rdv" routerLinkActive="active" class="nav-link">
                <i class="nav-icon fas fa-calendar-check"></i>
                <p>RDV</p>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="/maintenance/notification" routerLinkActive="active" style="cursor: pointer;" class="nav-link">
                <i class="nav-icon fas fa-bell"></i>
                <p>Notifications</p>
              </a>
            </li>
          </ng-container>
          <li class="nav-header">PERSONNEL</li>
          <li class="nav-item">
            <a routerLink="/client" routerLinkActive="active" class="nav-link">
              <i class="nav-icon fas fa-user-alt"></i>
              <p>Client</p>
            </a>
          </li>
          <li *ngIf="user?.type == 'admin' || user?.employe?.service?.libelle == LOGICIEL" class="nav-item">
            <a routerLink="/les-services" routerLinkActive="active" class="nav-link">
              <i class="nav-icon fas fa-boxes"></i>
              <p>Service</p>
            </a>
          </li>
          <li *ngIf="user?.type == 'admin' || user?.employe?.service?.libelle == LOGICIEL" class="nav-item">
            <a routerLink="/personnel" routerLinkActive="active" style="cursor: pointer;" class="nav-link">
              <i class="nav-icon fas fa-users"></i>
              <p>Employe</p>
            </a>
          </li>

          <li class="nav-header">Autres</li>
          <li *ngIf="user?.type == 'admin' || user?.employe?.service?.libelle == LOGICIEL || user?.employe?.service?.libelle == COMPTABILITE" class="nav-item">
            <a routerLink="/devise" routerLinkActive="active" class="nav-link">
              <i class="nav-icon fas fa-money-check-alt"></i>
              <p>Devise</p>
            </a>
          </li>
          <li *ngIf="user?.type == 'admin' || user?.employe?.service?.libelle == LOGICIEL || user?.employe?.service?.libelle == COMPTABILITE" class="nav-item">
            <a routerLink="/mode-paiement" routerLinkActive="active" class="nav-link">
              <!-- <i class="nav-icon fas fa-money"></i> -->
              <i class="nav-icon fas fa-credit-card" aria-hidden="true"></i>
              <p>Mode de paiements</p>
            </a>
          </li>
          <!-- <li class="nav-item">
            <a style="cursor: pointer;" class="nav-link">
              <i class="nav-icon fas fa-globe"></i>
              <p>Aller sur le site</p>
            </a>
          </li> -->

          <li class="nav-header">AUTHENTIFICATION</li>
          <li class="nav-item">
            <a style="cursor: pointer;" (click)="logout()" class="nav-link">
              <i class="nav-icon fas fa-sign-out-alt"></i>
              <p>Deconnexion</p>
            </a>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
