import { EmployeModel } from './employe.model';
import { ClientModel } from './client.model';
export class UserModel {
    constructor(
        public id: number,
        public name: string,
        public phone: string,
        public type: string,
        public statut: string,
        public tk_token: string,
        public slug: string,
        public confirm_compte: string,
        public sexe?: string,
        public photo?: string,
        public adresse?: string,
        public email?: string,
        public phone_verified?: number,

        public client?: ClientModel,
        public employe?: EmployeModel
    ) {}
}
