import { Role } from './../../../../models/role.model';
import { UserModel } from './../../../../models/user.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { DefaultService } from './default.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userConnected: BehaviorSubject<UserModel> = new BehaviorSubject(
    new UserModel(null, null, null, null, null, null, null, null)
  );

  constructor(
    private defaultSvc: DefaultService,
    private http: HttpClient
  ) { }

  login(user: any) {
    return this.http.post(this.defaultSvc.urlApi + 'login', user);
  }

  loadUser(webToken: any) {
    return this.http.get(this.defaultSvc.urlWeb + 'loaduser', webToken);
  }

  logout(httpOption = null) {
    return this.http.post(this.defaultSvc.urlWeb + 'logout', [], httpOption);
  }

  updatePassword(data): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.post(this.defaultSvc.urlWeb + 'modifPassword', data, httpOptions);
  }
}
