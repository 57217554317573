<!-- Navbar -->
<nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>
      <!-- <li class="nav-item d-none d-sm-inline-block">
        <a href="index3.html" class="nav-link">Home</a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="#" class="nav-link">Contact</a>
      </li> -->
    </ul>

    <!-- SEARCH FORM -->
    <!-- <form class="form-inline ml-3">
      <div class="input-group input-group-sm">
        <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
        <div class="input-group-append">
          <button class="btn btn-navbar" type="submit">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </form> -->

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-comments"></i>
          <span class="badge badge-danger navbar-badge">3</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <a href="#" class="dropdown-item">
            <div class="media">
              <img src="../../../assets/dist/img/user1-128x128.jpg" alt="User Avatar" class="img-size-50 mr-3 img-circle">
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  Brad Diesel
                  <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>
                </h3>
                <p class="text-sm">Call me whenever you can...</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <div class="media">
              <img src="../../../assets/dist/img/user8-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  John Pierce
                  <span class="float-right text-sm text-muted"><i class="fas fa-star"></i></span>
                </h3>
                <p class="text-sm">I got your message bro</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <div class="media">
              <img src="../../../assets/dist/img/user3-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  Nora Silvester
                  <span class="float-right text-sm text-warning"><i class="fas fa-star"></i></span>
                </h3>
                <p class="text-sm">The subject goes here</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell"></i>
          <span class="badge badge-warning navbar-badge">15</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">15 Notifications</span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-envelope mr-2"></i> 4 new messages
            <span class="float-right text-muted text-sm">3 mins</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-users mr-2"></i> 8 friend requests
            <span class="float-right text-muted text-sm">12 hours</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-file mr-2"></i> 3 new reports
            <span class="float-right text-muted text-sm">2 days</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
          <i class="fas fa-th-large"></i>
        </a>
      </li> -->

      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown">
          <i class="fas fa-unlock-alt"></i>
          <!-- <span class="badge badge-warning navbar-badge">15</span> -->
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">Modifier le mot de passe</span>
          <div class="dropdown-divider"></div>
          <form [formGroup]="fromGroup">
            <div class="card-body">
              <div class="form-group">
                <label for="exampleInputEmail1">Ancien mot de passe</label>
                <div class="input-group">
                  <input [type]="typeHoldPassword" formControlName="password" class="form-control" id="exampleInputEmail1" placeholder="password">
                  <div (click)="changeType('password', $event)" class="input-group-append">
                    <div class="input-group-text"><i [class]="eyeHoldPassword"></i></div>
                  </div>
                </div>
                <p style="color: red;" *ngIf="fromGroup.get('password').touched && fromGroup.get('password').hasError('required')">L'actuel mot de passe est obligatoire</p>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Nouveau mot de passe</label>
                <div class="input-group">
                  <input [type]="typeNewPassword" formControlName="npassword" class="form-control" id="exampleInputEmail1" placeholder="password">
                  <div (click)="changeType('newPassword', $event)" class="input-group-append">
                    <div class="input-group-text"><i [class]="eyeNewPassword"></i></div>
                  </div>
                </div>
                <p style="color: red;" *ngIf="fromGroup.get('npassword').touched && fromGroup.get('npassword').hasError('required')">Le nouveau mot de passe est obligatoire</p>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Confirmer mot de passe</label>
                <div class="input-group">
                  <input [type]="typeConfirmPassword" formControlName="cpassword" class="form-control" id="exampleInputEmail1" placeholder="password">
                  <div (click)="changeType('confirmPassword', $event)" class="input-group-append">
                    <div class="input-group-text"><i [class]="eyeConfirmPassword"></i></div>
                  </div>
                </div>
                <p style="color: red;" *ngIf="fromGroup.get('cpassword').touched && fromGroup.get('cpassword').hasError('notEquivalent')">Les deux mot de passe sont differents</p>
              </div>
            </div>
            <div *ngIf="!verif" class="row m-2 float-right">
              <div class="col-6">
                <button (click)="annuler($event)" type="button" class="btn btn-default btn-sm">Annuler</button>
              </div>
              <div class="col-6">
                <button (click)="onSave($event)" type="button" class="btn btn-primary btn-sm">Enregistrer</button>
              </div>
            </div>
            <div *ngIf="verif" class="row m-2">
              <p>Veuillez patienter..</p>
            </div>
          </form>
        </div>
      </li>

    </ul>
  </nav>
  <!-- /.navbar -->

