import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class DefaultService {

  constructor() { }

  // urlBase = 'http://127.0.0.1:8000';
  // urlApi = 'http://127.0.0.1:8000/api/';
  // urlWeb = 'http://127.0.0.1:8000/api/web/';


  // urlBase = 'http://192.168.88.57:8000';
  // urlApi = 'http://192.168.88.57:8000/api/';
  // urlWeb = 'http://192.168.88.57:8000/api/web/';

  // urlBase = 'https://api.makytransit.com';
  // urlApi = 'https://api.makytransit.com/api/';
  // urlWeb = 'https://api.makytransit.com/api/web/';

  urlBase = 'https://api.tinkistoree.com';
  urlApi = 'https://api.tinkistoree.com/api/';
  urlWeb = 'https://api.tinkistoree.com/api/web/';

  // for datatable
  initDataTables(tbTag: string, itemNumber = 0) {
    const item_par_page = (itemNumber > 0) ? itemNumber : 10;
    $(tbTag).DataTable({
      lengthMenu: [[item_par_page, 50, 100, -1], [item_par_page, 15, 25, 50, 100, 'All']],
      language: {
        processing: 'Traitement en cours ...',
        lengthMenu: 'Afficher _MENU_ lignes',
        zeroRecords: 'Aucun résultat trouvé',
        emptyTable: 'Aucune donnée disponible',
        info: 'Lignes _START_ à _END_ sur _TOTAL_',
        infoEmpty: 'Aucune ligne affichée',
        infoFiltered: '(Filtrer un maximum de _MAX_)',
        infoPostFix: '',
        search: 'Recherche:',
        url: '',
        thousands: ',',
        loadingRecords: 'Chargement...',
        paginate: {
          first: 'Premier', last: 'Dernier', next: 'Suivant', previous: 'Précédent'
        },
        aria: {
          sortAscending: ': Trier par ordre croissant', sortDescending: ': Trier par ordre décroissant'
        }
      }
    });
  }

  dtyDataTables(tbTag: string) {
    $(tbTag).DataTable().destroy();
  }

  closeSideBar(): void {
    if (window.innerWidth < 993) {
      let body = document.getElementsByTagName('body')[0];
      body.classList.remove("sidebar-open");
      body.classList.add("sidebar-closed");
      body.classList.add("sidebar-collapse");
    }
  }
}
