import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'produit',
    canActivate: [AuthGuard],
    // loadChildren: () => import('./produit/produit.module').then(m => m.ProduitModule)
    loadChildren: () => import('./produit/produit.module').then(m => m.ProduitModule)
  },
  {
    path: 'personnel',
    canActivate: [AuthGuard],
    loadChildren: () => import('./personnel/personnel.module').then(m => m.PersonnelModule)
  },
  {
    path: 'vente',
    canActivate: [AuthGuard],
    loadChildren: () => import('./vente/vente.module').then(m => m.VenteModule)
  },
  {
    path: 'les-services',
    canActivate: [AuthGuard],
    loadChildren: () => import('./les-services/les-services.module').then(m => m.LesServicesModule)
  },
  {
    path: 'categorie',
    canActivate: [AuthGuard],
    loadChildren: () => import('./categorie/categorie.module').then(m => m.CategorieModule)
  },
  {
    path: 'stock',
    canActivate: [AuthGuard],
    loadChildren: () => import('./stock/stock.module').then(m => m.StockModule)
  },
  {
    path: 'devise',
    canActivate: [AuthGuard],
    loadChildren: () => import('./device/device.module').then(m => m.DeviceModule)
  },
  {
    path: 'client',
    canActivate: [AuthGuard],
    loadChildren: () => import('./client/client.module').then(m => m.ClientModule)
  },
  {
    path: 'mode-paiement',
    canActivate: [AuthGuard],
    loadChildren: () => import('./mode-paiement/mode-paiement.module').then(m => m.ModePaiementModule)
  },
  {
    path: 'caisse',
    canActivate: [AuthGuard],
    loadChildren: () => import('./caisse/caisse.module').then(m => m.CaisseModule)
  },
  {
    path: 'maintenance',
    canActivate: [AuthGuard],
    loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule)
  },
  {
    path: 'approvisionnement',
    canActivate: [AuthGuard],
    loadChildren: () => import('./approvisionnement/approvisionnement.module').then(m => m.ApprovisionnementModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
