import { DefaultService } from './../../services/default.service';
import { HttpHeaders } from '@angular/common/http';
import { Role } from './../../../../../models/role.model';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import Swal from 'sweetalert2';
import { UserModel } from 'projects/models/user.model';

declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  user: UserModel;
  ADMIN = Role.ADMIN;
  PERSONNEL = Role.PERSONNEL;
  LOGICIEL = Role.LOGICIEL;
  COMMERCIAL = Role.COMMERCIAL;
  TECHNIQUE = Role.TECHNIQUE;
  COMPTABILITE = Role.COMPTABILITE;
  LOGISTIQUE = Role.LOGISTIQUE;
  SECURITE = Role.SECURITE;

  urlBase: string;

  constructor(
    private authSvc: AuthService,
    private router: Router,
    private defaultSvc: DefaultService
  ) { }

  ngOnInit(): void {
    this.urlBase = this.defaultSvc.urlBase;
    this.authSvc.userConnected.subscribe(
      user => {
        this.user = user;
      }
    );
  }

  logout() {
    Swal.fire({
      title: 'Etes vous?',
      text: 'pour la deconnexion',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui'
    }).then((result) => {
      if (result.isConfirmed) {
        const httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('web_token')}`
          })
        };
        this.authSvc.logout(httpOptions).subscribe(
          res => {
            console.log(res);
            localStorage.clear();
            this.authSvc.userConnected.next(new UserModel(null, null, null, null, null, null, null, null));
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: res['message'],
              showConfirmButton: false,
              timer: 3000
            });
            this.router.navigateByUrl('/auth/login', {replaceUrl: true});
          }
        )
      }
    });
  }
}
