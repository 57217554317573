import { UserModel } from './../../../models/user.model';
import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'TinkiStore ADMIN';
  public user: UserModel;

  constructor(
    private authSvc: AuthService
  ) {
    this.authSvc.userConnected.subscribe(
      res => {
        console.log(res);
        this.user = res;
      }
    );
  }
}
